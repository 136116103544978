<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
      color="white"
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>
        mdi-help-circle-outline
      </v-icon>
    </v-btn>
      </template>

    <v-card>
        <v-card-title class="text-h5 lighten-2 mb-2">
          Информация
        </v-card-title>

        <v-card-text >
        <v-row>
          <v-col cols="12">
            <strong>Версия API:</strong> {{ versionAPI }}
          </v-col>
          <v-col cols="12">
            <strong>Версия Frontend:</strong> {{ versionFront }}
          </v-col>
        </v-row>
      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>

export default {
  name: 'ButtonInfo',
  props: {
    versionAPI: {
      type: String,
      default: '-'
    },
    versionFront: {
      type: String,
      default: '-'
    }
  },
  data () {
      return {
        dialog: false,
      }
    },
};
</script>