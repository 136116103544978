<template>
  <v-container>
    <v-snackbar
      :timeout="3000"
      v-model="snackError"
      fixed
      centered
      center
      color="error"
      elevation="24"
    >
      Не удалось создать экспертизу
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="snackSuccess"
      fixed
      centered
      center
      color="success"
      elevation="24"
    >
      Экспертиза успешно создана
    </v-snackbar>
    <ExpertiseCard
      v-for="(item, i) in this.$store.getters.getAllExpertises"
      :key="i"
      :expertise="item"
    />
    <v-btn color="pink" dark fixed bottom right fab @click="dialog = !dialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Новая экспертиза</v-card-title>
        <v-card-text>
          <v-form v-model="isValid" ref="newCaseForm">
            <v-text-field
              label="Номер дела"
              required
              v-model="caseNumber"
              :rules="[(v) => v.length > 0 || 'Обязательное поле']"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitNewCase"> Создать </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import * as fetchExpertise from "@/api/expertiseApi";
import ExpertiseCard from "./ExpertiseCard.vue";

export default {
  name: "ExpertisesList",
  components: { ExpertiseCard },
  data: () => ({
    dialog: false,
    isValid: true,
    caseNumber: "",
    snackError: false,
    snackSuccess: false,
  }),
  methods: {
    openCreateModal() {
      this.$refs.newCaseForm.reset();
      this.dialog = true;
    },
    async submitNewCase() {
      this.$refs.newCaseForm.validate();
      if (!this.isValid) return;
      console.log(this.isValid);
      try {
        let response = await fetchExpertise.createExpertise(this.caseNumber);
        if (response.ok) {
          let data = await response.json();
          this.$store.commit("addExpertise", data);
          this.snackSuccess = true;
        } else {
          this.snackError = true;
        }
        this.dialog = false;
      } catch {
        this.dialog = false;
        this.snackError = true;
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchExpertises");
  },
};
</script>
