<template>
  <v-form>
    <v-container>
      <v-card v-if="errorGetFetch">Ошибка при получении данных</v-card>
      <v-row>
        <v-col>
          <v-text-field
            label="Идентификатор"
            outlined
            dense
            required
            readonly
            disabled
            v-model="formData.id"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.courtCaseNumber"
            label="Номер дела"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formatdateCreate"
            dense
            outlined
            label="Дата создания"
            disabled
            prepend-inner-icon="mdi-calendar"
          ></v-text-field>
        </v-col>
        <!-- Дата завершения осмотра -->
        <v-col cols="12" md="6">
          <v-menu
            ref="menuDateCompletion"
            v-model="dateCompletionModel"
            :close-on-content-click="false"
            :return-value.sync="formData.dateCompletion"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="formatDateCompletion"
                outlined
                label="Дата завершения осмотра"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.dateCompletion"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateCompletionModel = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuDateCompletion.save(formData.dateCompletion)"
              >
                Выбрать
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select
            :items="selectData.status"
            v-model="formData.state"
            label="Статус"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            :items="selectData.priority"
            v-model="formData.priority"
            label="Приоритет"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            :items="selectData.stateInspection"
            v-model="formData.stateInspection"
            label="Статус осмотра"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formData.court"
            label="Суд"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.courtRegion"
            label="Регион суда"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.courtAddress"
            label="Адрес суда"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.judge"
            label="Судья"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formData.organization"
            label="Организация"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.addressObject"
            label="Адрес осмотра"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menuDateInspection"
            v-model="dateInspectionModel"
            :close-on-content-click="false"
            :return-value.sync="formData.dateInspection"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="formatDateInspection"
                label="Дата осмотра"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.dateInspection"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateInspectionModel = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuDateInspection.save(formData.dateInspection)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Дата начала осмотра -->
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menuDateInspectionStart"
            v-model="dateInspectionStartModel"
            :close-on-content-click="false"
            :return-value.sync="formData.dateInspectionStart"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="formatDateInspectionStart"
                label="Дата начала осмотра"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.dateInspectionStart"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateInspectionStartModel = false"
              >
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menuDateInspectionStart.save(
                    formData.dateInspectionStart
                  )
                "
              >
                Выбрать
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Дата завершения осмотра -->
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menuDateInspectionEnd"
            v-model="dateInspectionEndModel"
            :close-on-content-click="false"
            :return-value.sync="formData.dateInspectionEnd"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="formatDateInspectionEnd"
                label="Дата завершения осмотра"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.dateInspectionEnd"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateInspectionEndModel = false"
              >
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menuDateInspectionEnd.save(formData.dateInspectionEnd)
                "
              >
                Выбрать
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-alert dense outlined type="error" dismissible v-model="errorAlert">
          Не удалось сохранить изменения
          {{ errorText }}
        </v-alert>
        <v-alert
          dense
          outlined
          type="success"
          dismissible
          v-model="succesAlert"
        >
          {{ textSuccess }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn
          :loading="saving"
          class="mr-4"
          @click="updateFetch()"
          color="primary"
        >
          Сохранить
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
//   +++"id": 55,
//   +++"courtCaseNumber": "123",
//   "dateCreate": "17.09.2024 12:45:05.708",
//   +++"state": "Создан",
//   +++"priority": "Обычный",
//   +++"progress": 28,
//   +++"court": "",
//   +++"courtAddress": "",
//   +++"judge": "",
//   "dateCompletion": "",
//   +++"dateInspection": "",
//   +++"organization": "",
//   +++"addressObject": "",

//   +"courtRegion": "",
//   -"daysLeft": -1,
//   "stateInspection": "Не выполнен",
//   +++"dateInspectionStart": "",
//   +++"dateInspectionEnd": ""
import * as fetchEx from "@/api/expertiseApi";
export default {
  props: {
    idExpertise: {
      required: true,
    },
  },
  created() {
    this.fetchExpertise();
  },
  computed:{
    formatDateInspectionEnd(){
      if(!this.formData.dateInspectionEnd) return '';
      let date = new Date(this.formData.dateInspectionEnd);
      return date.toLocaleDateString("ru-RU");
    },
    formatDateInspectionStart(){
      if(!this.formData.dateInspectionStart) return '';
      let date = new Date(this.formData.dateInspectionStart);
      return date.toLocaleDateString("ru-RU");
    },
    formatDateInspection(){
      if (!this.formData.dateInspection) return '';
      let date = new Date(this.formData.dateInspection);
      return date.toLocaleDateString("ru-RU");
    },
    formatDateCompletion(){
      if (!this.formData.dateCompletion) return '';
      let date = new Date(this.formData.dateCompletion);
      return date.toLocaleDateString("ru-RU");
    },
    formatdateCreate(){
      if (!this.formData.dateCreate) return '';
      let date = new Date(this.formData.dateCreate);
      return date.toLocaleDateString("ru-RU");
    }
  },
  methods: {
    formatServerDate(d) {
      if (!d) return "";
      let res = new Date(d);
      res = res.toISOString();
      res = res.replace("T", " ");
      res = res.replace("Z", "");
      //   res = res.replaceAll("-", '.');
      res = res.split(" ");
      let date = res[0];
      date = date.split("-").reverse().join(".");

      return `${date} ${res[1]}`;
    },
    async updateFetch() {
      this.sendData = {};
      Object.assign(this.sendData, this.formData);
      this.sendData.dateInspection = this.formatServerDate(
        this.sendData.dateInspection
      );
      this.sendData.dateInspectionStart = this.formatServerDate(
        this.sendData.dateInspectionStart
      );
      this.sendData.dateInspectionEnd = this.formatServerDate(
        this.sendData.dateInspectionEnd
      );
      this.sendData.dateCompletion = this.formatServerDate(
        this.sendData.dateCompletion
      );
      this.sendData.dateCreate = this.formatServerDate(
        this.sendData.dateCreate
      );
      console.log(this.sendData);
      this.saving = true;
      try {
        delete this.sendData['dateCreate'];
        delete this.sendData['progress'];
        delete this.sendData['daysLeft'];
        let res = await fetchEx.updateExpertiseCommonInfo(this.sendData);
        if (!res.ok) {
          console.log(res,'ew');
          let o = await res.text();
          this.errorText = o;
          this.errorAlert = true;
          this.saving = false;
          return;
        }
        let resText = await res.text();
        this.textSuccess = resText;
        this.succesAlert = true;
      } catch(e) {
        this.errorAlert = true;
        this.errorText = e.message;
      }
      this.saving = false;
      // console.log(this.sendData);
    },
    getDate(d) {
      if (!d) return "";
      let date = d.split(" ");
      let dateArr = date[0].split(".");
      let dateTime = date[1].split(":");
      let dateString = new Date(
        parseInt(dateArr[2]),
        parseInt(dateArr[1]),
        parseInt(dateArr[0]),
        parseInt(dateTime[0]),
        parseInt(dateTime[1])
      );
      return dateString;
    },
    getDateIso(d) {
      if (!d) return "";
      let date = d.split(" ");
      let dateArr = date[0].split(".");
      let dateTime = date[1].split(":");
      let dateString = new Date(
        parseInt(dateArr[2]),
        parseInt(dateArr[1]),
        parseInt(dateArr[0]),
        parseInt(dateTime[0]),
        parseInt(dateTime[1])
      );
      return dateString.toISOString().substr(0, 10);
    },
    async fetchExpertise() {
      let res = await fetchEx.getExpertiseInfo(this.idExpertise);
      if (!res.ok) {
        this.errorGetFetch = true;
        return;
      }
      this.errorGetFetch = false;
      let data = await res.json();
      console.log(data);
      data.dateCreate = this.getDateIso(data.dateCreate);
      data.dateCompletion = this.getDateIso(data.dateCompletion);
      data.dateInspection = this.getDateIso(data.dateInspection);
      data.dateInspectionStart = this.getDateIso(data.dateInspectionStart);
      data.dateInspectionEnd = this.getDateIso(data.dateInspectionEnd);
      Object.assign(this.formData, data);
    },
  },
  data: () => ({
    errorText:'',
    errorAlert: false,
    succesAlert: false,
    textSuccess: "",
    saving: false,
    sendData: {},
    errorGetFetch: false,
    dateInspectionModel: false,
    dateInspectionStartModel: false,
    dateInspectionEndModel: false,
    dateCompletionModel: false,
    selectData: {
      status: ["Создан", "В работе", "Выполнен", "Отменён"],
      priority: ["Срочный", "Высокий", "Обычный", "Низкий"],
      stateInspection: ["Не выполнен","В работе","Выполнен"],
    },
    formData: {
      id: null,
      courtCaseNumber: null,
      state: null,
      priority: null,
      progress: null,
      court: null,
      courtAddress: null,
      judge: null,
      organization: null,
      addressObject: null,
      dateCreate: null,
      dateInspection: null,
      dateInspectionStart: null,
      dateInspectionEnd: null,
      dateCompletion: null,
      stateInspection:null,
    },
  }),
};
</script>

<style></style>
