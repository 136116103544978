<template>
  <v-row>
    <v-col>
      <v-card elevation="2" class="mx-auto" max-width="600" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="d-flex align-center justify-lg-space-between">
              <span class="text-overline mb-4"> #{{ expertise.id }} </span>
              <span class="text-sm-body-2 mb-4 text--secondary ml-4">
                <v-icon small>mdi-calendar-import-outline</v-icon>
                Дата создания: {{ getDate(expertise.dateCreate) }}</span
              >
            </div>
            <v-row>
              <v-col>
                <v-list-item-title class="text-h5 mb-1">
                  Дело: {{ expertise.courtCaseNumber }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon small>mdi-domain</v-icon>
                  {{ expertise.organization || "Не указано" }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>
                  <v-icon small>mdi-gavel</v-icon>
                  {{ expertise.court || "Не указано" }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>
                  <v-icon small>mdi-account</v-icon>
                  {{ expertise.judge || "Не указано" }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>
                  <v-icon small>mdi-map-marker-outline</v-icon>
                  {{
                    expertise.addressObject || "Не указано"
                  }}</v-list-item-subtitle
                >

              </v-col>
              <v-col class="col-auto">
                <v-progress-circular
                  :rotate="-90"
                  :size="100"
                  :width="15"
                  :value="expertise.progress"
                  color="primary"
                >
                  <span class="text-h5">{{ expertise.progress }}%</span>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
          <StatusChip :status="expertise.state" />
          <PriorityChip :priority="expertise.priority"/>
          <DaysLeft :days="new Number(expertise.daysLeft)" v-if="expertise.dateCompletion.length>0" />
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="2"
            small
            :to="'expertise/' + expertise.id"
          >
            <v-icon dark left> mdi-pencil </v-icon>
            Редактировать</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DaysLeft from './DaysLeft.vue';
import PriorityChip from "./PriorityChip.vue";
import StatusChip from "./StatusChip.vue";
export default {
  components:{
    DaysLeft,
    StatusChip,
    PriorityChip,
  },
  props: {
    expertise: {
      type: Object,
      require: true,
    },
  },
  methods: {
    getDate(d) {
      let date = d.split(" ");
      let dateArr = date[0].split(".");
      let dateTime = date[1].split(":");
      let dateString = new Date(
        parseInt(dateArr[2]),
        parseInt(dateArr[1]),
        parseInt(dateArr[0]),
        parseInt(dateTime[0]),
        parseInt(dateTime[1])
      );

      // return dateString.toLocaleString("ru-RU");
      return dateString.toLocaleDateString("ru-RU");
    },
  },
  computed: {
    getStateColor() {
      switch (this.expertise.state) {
        case "Выполнен":
          return "green";
        case "Отменён":
          return "default";
        case "Создан":
          return "blue";
        default:
          return "green";
      }
    },
    getPriotityColor() {
      switch (this.expertise.priority) {
        case "Срочный":
          return "red";
        case "Высокий":
          return "orange";
        case "Обычный":
          return "primary";
        case "Низкий":
          return "default";
        default:
          return "green";
      }
    },
  },
};
</script>

<style></style>
