<template>
    <div class="gallery-container">
      <!-- Кнопка для листания влево -->
      <v-btn icon @click="scrollLeft" class="gallery-btn left">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- Галерея изображений -->
      <div v-if="images.length == 0" class="text-center flex">Фотографии отсутствуют</div>
      <div class="gallery-wrapper" v-if="images.length > 0">
        <div class="gallery-list">        
          <div class="gallery-item" v-for="(image, index) in images" :key="index">
            <!-- Картинка -->
            <img :src="image" alt="Gallery Image" />

            <!-- Кнопка удаления -->
            <v-btn 
              icon 
              class="delete-btn" 
              @click="removeImage(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Кнопка для листания вправо -->
      <v-btn icon @click="scrollRight" class="gallery-btn right">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scrollLeft() {
      const gallery = this.$el.querySelector('.gallery-wrapper');
      gallery.scrollBy({ left: -200, behavior: 'smooth' });
    },
    scrollRight() {
      const gallery = this.$el.querySelector('.gallery-wrapper');
      gallery.scrollBy({ left: 200, behavior: 'smooth' });
    },
    removeImage(index) {
      this.$emit('remove-image', index);
    },
  },
};
</script>

<style scoped>
/* Контейнер галереи */
.gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

/* Кнопки */
.gallery-btn {
  margin: 0 10px;
}

.gallery-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  scroll-behavior: smooth;
  max-height: 6cm;
}

/* Список галереи */
.gallery-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

/* Элемент галереи */
.gallery-item {
  position: relative;
  flex: 0 0 auto;
  width: 5cm;
  height: 5cm;
  margin-right: 10px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Кнопка удаления */
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
}
</style>