<template>
  <div class="home">
    <v-container>
      <LogsComponent/>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import LogsComponent from '../components/LogsComponent.vue';

export default {
  name: "Home",
  components: {
    LogsComponent,
  },
};
</script>
