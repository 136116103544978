import { BASE_URL } from "./baseConst";
//https://expertise-develop-bse.amvera.io/version
export function createExpertise(courtCaseNumber) {
  return fetch(
    `${BASE_URL}/expertises/create?courtCaseNumber=${courtCaseNumber}`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        courtCaseNumber: courtCaseNumber,
      }),
    }
  );
}

export function updateExpertiseCommonInfo(expertiseData) {
  return fetch(`${BASE_URL}/expertises/edit`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(expertiseData),
  });
}

export function getExpertiseInfo(idExpertise) {
  return fetch(`${BASE_URL}/expertises/get?id=${idExpertise}`);
}

export function getAllInspObject(idExpertise) {
  return fetch(`${BASE_URL}/insp_objs?idExpertise=${idExpertise}`);
}

export function uploadFoto(idExpertise, formData) {
  return fetch(`${BASE_URL}/insp_objs/add_photo?id=${idExpertise}`, {
    method: "POST",
    body: formData,
  });
}

export function removeObjInspection(id){
  return fetch(`${BASE_URL}/insp_objs/delete?id=${id}`, {
    method: "DELETE",
  });
}

export function getAllObjectsNames() {
  return fetch(`${BASE_URL}/insp_objs/get_names`);
}

export function createNewObjectInspection(formData){
  return fetch(`${BASE_URL}/insp_objs/create`, {
    method: "POST",
    body: formData,
  });
}

export function generateDocx(id){
  return fetch(`${BASE_URL}/insp_objs/table?idExpertise=${id}`);
}

export function getVersionApi(){
  return fetch(`${BASE_URL}/version`);
}

//Получить лог сервера
export function getLogs(){
  return fetch(`${BASE_URL}/logs`);
}