import { getAllObjectsNames } from "../api/expertiseApi";
export default {
  state: {
    expertises: [],
    objects_inspection:[]
  },
  mutations: {
    setExpertises(state, payload) {
      state.expertises = payload;
    },
    addExpertise(state, payload) {
      state.expertises.unshift(payload);
    },
    setObjectsInspection(state, payload){
      state.objects_inspection = payload
    }
  },
  getters: {
    getAllExpertises(state) {
      return state.expertises;
    },
    getObjectsInspection(state){
      return state.objects_inspection;
    },
  },
  actions: {
    // TODO Вызывать из методов api
    fetchExpertises(context) {
      fetch("https://expertise-develop-bse.amvera.io/api/v1/expertises")
        .then((response) => response.json())
        .then((data) => context.commit("setExpertises", data.listExpertises));
    },
    fetchAllObjectsInspection(context){
      getAllObjectsNames()
        .then((response) => response.json())
        .then((data) => context.commit("setObjectsInspection", data.listInspObjsNames));
    }
  },
};
